<template>
    <div class="section-1"
        :style="{ 'background-image': 'url(' + bg + ')', 'background-size': 'cover', 'background-repeat': 'no-repeat', 'padding': '140px 0 200px', 'background-position': 'center' }">

        <img :src="img3" alt="" class="cloud1 show-right">
        <img :src="img4" alt="" class="cloud2 show-right">
        <img :src="img2" alt="" class="hand show-right">
        <img :src="img5" alt="" class="other show-up">

        <div class="container">
            <div class="col-sm-12 col-lg-6">
                <img class="show-up" :src="img1" alt=""
                    :style="{ 'width': '70%', 'display': 'block', 'margin': 'auto', 'position': 'relative', 'z-index': 2 }">
                <button class="fw-bold hovering"
                    :style="{ 'position': 'relative', 'z-index': 2, 'display': 'block', 'font-size': '16px', 'margin': '50px auto', 'width': '100%', 'max-width': '448px', 'height': '42px', 'border-radius': '8px', 'border': 'none', 'color': 'white', 'background-color': '#065F66' }">
                    Đăng Ký Ngay
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Section1",
    data() {
        return {
            bg: require("@/images/section1/bg.png"),
            img1: require("@/images/section1/1.png"),
            img2: require("@/images/section1/2.png"),
            img3: require("@/images/section1/3.png"),
            img4: require("@/images/section1/4.png"),
            img5: require("@/images/section1/5.png"),
        }
    }
}
</script>

<style lang="scss">
.section-1 {
    position: relative;

    .hand,
    .cloud1,
    .cloud2,
    .other {
        position: absolute;
    }

    .hand {
        top: 20%;
        left: 0;
        width: 10%;

        @media screen and (max-width: 767px) {
            width: 20%;
        }
    }

    .cloud1 {
        bottom: 0;
        left: 0;
        width: 40%;

        @media screen and (max-width: 767px) {
            width: 80%;
        }
    }

    .cloud2 {
        top: 0;
        left: 0;
        width: 50%;

        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }

    .other {
        top: 60%;
        left: 20%;
        width: 30%;

        @media screen and (max-width: 767px) {
            width: 80%;
        }
    }
}
</style>