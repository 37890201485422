<template>
    <div ref="section2" class="section-2 container-fluid"
        :style="{ 'background-image': 'url(' + bg1 + ')', 'position': 'relative' }">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-sm-12" :style="{ 'padding-top': '100px' }">
                    <p class="nunito" :style="{ 'padding-right': '50px' }">
                        Hành vi, tâm lý của khách hàng thay đổi nhanh chóng, thương mại điện tử, xu hướng D2C và công nghệ
                        AI
                        lên ngôi một cách bùng nổ trong năm 2022 - 2023 khiến doanh nghiệp Việt đang phải đối mặt với nhiều
                        vấn
                        đề mới và phải đứng trước nhiều sự lựa chọn phương pháp tiếp thị.
                    </p>
                    <p class="nunito" :style="{ 'padding-right': '50px' }">
                        Bài toán gia tăng tỉ lệ chuyển đổi, mở rộng đa kênh, tối ưu chi phí song hành với tính sáng tạo, độc
                        đáo
                        của chiến lược truyền thông trở thành thách thức lớn. Vậy đâu là kịch bản tiếp thị đúng đắn, dẫn
                        doanh
                        nghiệp đi đúng hướng trong thời điểm biến động hiện nay?
                    </p>
                </div>
                <div class="col-lg-6 col-sm-12 d-none d-lg-block" :style="{ 'position': 'relative' }">
                    <img class="img1 opacity0" :src="img1" alt="" :style="{ 'width': '50%', 'margin-top': '100px' }">
                    <img class="img2 opacity0" :src="img2" alt=""
                        :style="{ 'position': 'absolute', 'width': '80%', 'left': '0px', 'top': '150px' }">
                    <img class="img3 opacity0" :src="img3" alt=""
                        :style="{ 'position': 'absolute', 'top': '50px', 'width': '30%', 'left': '350px' }">
                    <img class="img4 opacity0" :src="img4" alt=""
                        :style="{ 'position': 'absolute', 'bottom': '-200px', 'left': '150px', 'width': '20%' }">
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-sm-12">
                    <img class="img5 opacity0" :src="img5" alt=""
                        :style="{ 'width': '80%', 'display': 'block', 'margin': 'auto' }">
                </div>
                <div class="col-lg-6 col-sm-12" :style="{ 'position': 'relative' }">
                    <p class="nunito" :style="{ 'position': 'absolute', 'bottom': '100px' }">
                        Nhằm tiếp sức và giúp thương hiệu “cưỡi trên làn sóng đổi mới” này, Adsota chính thức tổ chức sự
                        kiện
                        <b>Marketing Trend Transformation: Gìn giữ chất riêng, bứt phá với phương pháp tiếp thị mới.</b> Sự
                        kiện
                        sẽ đưa ra những giải pháp cụ thể, thực tế để củng cố vị thế của doanh nghiệp trên thị trường, đảm
                        bảo
                        hướng đi phù hợp với mục tiêu kinh doanh và xây dựng thương hiệu trong tương lai.
                    </p>
                </div>
            </div>
        </div>
        <img :src="bg2" alt="" class="d-none d-lg-block"
            :style="{ 'position': 'absolute', 'z-index': 1, 'bottom': '0px', 'left': '50%', 'transform': 'translateX(-50%)', 'width': '75%' }">
        <div class="container" :style="{ 'margin-top': '300px' }">
            <div class="row">
                <div class="col-lg-6 col-sm-12 d-none d-lg-block" :style="{ 'z-index': 2 }">
                    <img class="img6 opacity0" :src="img6" alt="" :style="{ 'width': '120%', 'margin-left': '-100px' }">
                </div>
                <div class="col-lg-6 col-sm-12" :style="{ 'z-index': 2, 'color': 'white' }">
                    <p class="nunito" :style="{ 'font-size': '20px' }">
                        Là những "nghệ nhân" lành nghề với gần 10 năm kinh nghiệm trong lĩnh vực Marketing, Adsota thấu hiểu
                        vấn
                        đề của từng doanh nghiệp, từ đó “may đo", “nhào nặn" các giải pháp marketing độc nhất, sáng tạo, tỉ
                        mỉ
                        và cẩn trọng trong từng chiến dịch.
                    </p>
                    <p class="nunito" :style="{ 'font-size': '20px' }">
                        Chúng tôi tin rằng, sự kiện này là cơ hội Adsota cùng các chuyên gia đầu ngành trao gửi những thông
                        tin
                        giá trị, xu hướng, các giải pháp hiệu quả; đồng thời, giúp Quý khách mời, Quý doanh nghiệp mở rộng,
                        siết
                        chặt mối quan hệ với các đối tác khác trong cộng đồng.
                    </p>
                    <button
                        :style="{ 'font-size': '16px', 'width': '308px', 'height': '45px', 'border-radius': '8px', 'background-color': '#065F66', 'border': 'none', 'color': 'white' }">
                        Đăng ký ngay
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Section2",
    data() {
        return {
            bg1: require("@/images/section2/bg1.png"),
            bg2: require("@/images/section2/bg2.png"),
            img1: require("@/images/section2/1.png"),
            img2: require("@/images/section2/2.png"),
            img3: require("@/images/section2/3.png"),
            img4: require("@/images/section2/4.png"),
            img5: require("@/images/section2/5.png"),
            img6: require("@/images/section2/6.png"),
        }
    },
    created() {
        window.addEventListener('scroll', this.onScroll);
    },
    unmounted() {
        window.removeEventListener('scroll', this.onScroll);
    },
    methods: {
        onScroll() {
            var img1 = this.$el.querySelector('.img1');
            if (img1.getBoundingClientRect().top < window.innerHeight) {
                img1.classList.add('show-up')
            }
            var img2 = this.$el.querySelector('.img2');
            if (img2.getBoundingClientRect().top < window.innerHeight) {
                img2.classList.add('show-up')
            }
            var img3 = this.$el.querySelector('.img3');
            if (img3.getBoundingClientRect().top < window.innerHeight) {
                img3.classList.add('show-up')
            }
            var img4 = this.$el.querySelector('.img4');
            if (img4.getBoundingClientRect().top < window.innerHeight) {
                img4.classList.add('show-up')
            }
            var img5 = this.$el.querySelector('.img5');
            if (img5.getBoundingClientRect().top < window.innerHeight) {
                img5.classList.add('show-up')
            }
            var img6 = this.$el.querySelector('.img6');
            if (img6.getBoundingClientRect().top < window.innerHeight) {
                img6.classList.add('show')
            }
        }
    },
}
</script>

<style>
@media screen and (max-width: 1199px) {
    .section-2 p {
        padding: 0 !important;
        bottom: 0 !important;
        top: 0 !important;
        color: black !important;
        font-size: 18px;
    }

    .section-2 img {
        margin: auto !important;
    }

    .section-2 button {
        display: block;
        margin: auto !important;
    }

    .section-2 {
        padding-bottom: 100px;
    }
}
</style>