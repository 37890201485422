<template>
    <div class="section-8 pt-5">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-lg-6">
                    <Title title="Liên hệ với chúng tôi" :backgroundTitle="img9" fontSizeTitle="36px" position="left"
                        paddingTitle="10px 10px 10px 70px" />
                    <div class="nunito">
                        <p class="fst-italic mt-5">Nếu có mong muốn hợp tác và tài trợ sự kiện, phiền Quý anh/chị vui lòng
                            liên
                            hệ:
                        </p>
                        <p :style="{ 'color': '#065F66', 'font-size': '20px', 'font-weight': 'bold' }">Ms. Mai Tiên -
                            Business
                            Development Manager</p>
                        <img :src="img1" alt="">&nbsp;&nbsp;<span>0908 268 712 - 0779 054 099</span>
                        <br><br>
                        <img :src="img2" alt="">&nbsp;&nbsp;<span>tiennvm@appota.com</span>
                        <div class="line my-5"></div>
                        <p class="fst-italic mt-5">Nếu có thắc mắc về vé sign in và các nhu cầu khác của sự kiện, phiền Quý
                            anh/chị vui lòng liên hệ:</p>
                        <p :style="{ 'color': '#065F66', 'font-size': '20px', 'font-weight': 'bold' }">Mr. Nguyễn Tiến Đạt -
                            Marketing Executive</p>
                        <img :src="img1" alt="">&nbsp;&nbsp;<span>Tel/Zalo: 0967 437 380</span>
                        <br><br>
                        <img :src="img2" alt="">&nbsp;&nbsp;<span>datnt5@appota.com</span>
                        <div class="my-5"></div>
                        <div class="d-flex justify-content-left mb-5">
                            <img :style="{ 'display': 'inline-block', 'margin-right': '24px' }" :src="img3" alt="">
                            <img :style="{ 'display': 'inline-block', 'margin-right': '24px' }" :src="img4" alt="">
                            <img :style="{ 'display': 'inline-block', 'margin-right': '24px' }" :src="img5" alt="">
                            <img :src="img6" alt="">
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-6 nunito">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.493684081022!2d106.67709737576881!3d10.773449889375179!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752fca3750a199%3A0x4c8eefdc79efb00b!2sTF%20Office%20Building!5e0!3m2!1sen!2s!4v1682840200688!5m2!1sen!2s"
                        width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <p class="fw-bold mt-5" :style="{ 'font-size': '20px' }">Appota (HCM BRANCH):</p>
                    <img :src="img8" alt="">&nbsp;&nbsp;<span>5F, Dien Bien Phu 408, Ward 12, Dist. 10, Ho Chi Minh City,
                        Vietnam </span>
                    <br><br>
                    <img :src="img1" alt="">&nbsp;&nbsp;<span>(+84) 2866 769 489</span>
                    <div class="mb-5"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Title from './Title.vue'
export default {
    name: 'Section8',
    components: { Title },
    data() {
        return {
            img: require('@/images/section7/1.png'),
            img1: require('@/images/section8/1.png'),
            img2: require('@/images/section8/2.png'),
            img3: require('@/images/section8/3.png'),
            img4: require('@/images/section8/4.png'),
            img5: require('@/images/section8/5.png'),
            img6: require('@/images/section8/6.png'),
            img7: require('@/images/section8/7.png'),
            img8: require('@/images/section8/8.png'),
            img9: require('@/images/section8/9.png')
        }
    }
}
</script>
<style lang="scss">
.section-8 {
    background-color: #F0F0F0;

    .line {
        width: 100%;
        border: 1px solid #CCCCCC;
    }

    iframe {
        border: 15px solid white !important;
        height: 442px;
        width: 732px;

        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }
}
</style>