<template>
    <div class="section-7 mt-5 pt-5">
        <div class="container">
            <div class="row">
                <div class="col-md-7 col-sm-12 mb-5">
                    <Title class="mb-3" title="KHÁCH MỜI THAM DỰ <br> SỰ KIỆN DỰ KIẾN" :backgroundTitle="img1" />
                    <div class="d-flex justify-content-around mb-3 w-3">
                        <img :src="onepay" alt="">
                        <img :src="momo" alt="">
                        <img :src="appota" alt="">
                    </div>
                    <div class="d-flex justify-content-around w-3">
                        <img :src="phenikaa" alt="">
                        <img :src="uwe" alt="">
                        <img :src="lazada" alt="">
                    </div>
                </div>
                <div class="col-md-5 col-sm-12 mb-5">
                    <Title class="mb-3" title="ĐỐI TÁC BẢO TRỢ <br> TRUYỀN THÔNG DỰ KIẾN" :backgroundTitle="img1" />
                    <div class="d-flex justify-content-around mb-3 w-2-5">
                        <img :src="advertision" alt="">
                        <img :src="csmo" alt="">
                    </div>
                    <div class="d-flex justify-content-around w-2-5">
                        <img :src="uan" alt="">
                        <img class="opacity-0" :src="uan" alt="">
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <Title class="mt-3" title="CÁC ĐỐI TÁC TIÊU BIỂU" :backgroundTitle="img1" paddingTitle="25px" />
                <div class="col-sm-12 col-md-4 mb-3">
                    <p class="text-center mb-5">Tài chính/Ngân hàng</p>
                    <div class="d-flex justify-content-around mb-3 w-2">
                        <img :src="pti" alt="">
                        <img :src="momo2" alt="">
                    </div>
                    <div class="d-flex justify-content-around mb-3 w-2">
                        <img :src="seabank" alt="">
                        <img :src="napas" alt="">
                    </div>
                    <div class="d-flex justify-content-around mb-3 w-2">
                        <img :src="tpbank" alt="">
                        <img :src="seabank2" alt="">
                    </div>
                    <div class="d-flex justify-content-around mb-3 w-2">
                        <img :src="techcombank" alt="">
                        <img :src="vietcombank" alt="">
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 mb-3">
                    <p class="text-center mb-5">App & Gaming</p>
                    <div class="d-flex justify-content-around mb-3 w-2">
                        <img :src="perfectWorld" alt="">
                        <img :src="nexon" alt="">
                    </div>
                    <div class="d-flex justify-content-around mb-3 w-2">
                        <img :src="amanotes" alt="">
                        <img :src="kakao" alt="">
                    </div>
                    <div class="d-flex justify-content-around mb-3 w-4">
                        <img :src="huawei" alt="">
                        <img :src="vng" alt="">
                        <img :src="line" alt="">
                        <img :src="line" alt="" class="opacity-0">
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 mb-3">
                    <p class="text-center mb-5">Giáo Dục</p>
                    <div class="d-flex justify-content-around mb-3 w-2">
                        <img :src="sakura" alt="">
                        <img :src="uwe2" alt="">
                    </div>
                    <div class="d-flex justify-content-around mb-3 w-2">
                        <img :src="phenikaa2" alt="">
                        <img class="opacity-0" :src="phenikaa2" alt="">
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-sm-12 col-md-4 mb-3">
                    <p class="text-center mb-5">E-commerce</p>
                    <div class="d-flex justify-content-around mb-3 w-2">
                        <img :src="shopee" alt="">
                        <img :src="tiki" alt="">
                    </div>
                    <div class="d-flex justify-content-around mb-3 w-2">
                        <img :src="lazada" alt="">
                        <img :src="shopee2" alt="">
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 mb-3">
                    <p class="text-center mb-5">Khác</p>
                    <div class="d-flex justify-content-around mb-3 w-2">
                        <img :src="businessInsider" alt="">
                        <img :src="bamboo" alt="">
                    </div>
                    <div class="d-flex justify-content-around mb-3 w-2">
                        <img :src="maybelline" alt="">
                        <img :src="loreal" alt="">
                    </div>
                    <div class="d-flex mb-3">
                        <div class="d-flex justify-content-around" :style="{ 'width': '50%' }">
                            <img :src="greatBig" alt="" :style="{ 'width': '44%' }">
                            <img :src="lg" alt="" :style="{ 'width': '44%' }">
                        </div>
                        <div class="d-flex justify-content-center" :style="{ 'width': '50%' }">
                            <img :src="coccoc" alt="" :style="{ 'width': '94%' }">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Title from './Title.vue'

export default {
    name: 'Section7',
    components: { Title },
    data() {
        return {
            img1: require("@/images/section7/1.png"),
            advertision: require("@/images/section7/advertision.png"),
            onepay: require("@/images/section7/onepay.png"),
            momo: require("@/images/section7/momo.png"),
            advertision: require("@/images/section7/advertision.png"),
            amanotes: require("@/images/section7/amanotes.png"),
            businessInsider: require("@/images/section7/business-insider.png"),
            bamboo: require("@/images/section7/bamboo.png"),
            appota: require("@/images/section7/appota.png"),
            coccoc: require("@/images/section7/coccoc.png"),
            csmo: require("@/images/section7/csmo.png"),
            greatBig: require("@/images/section7/great-big.png"),
            huawei: require("@/images/section7/huawei.png"),
            kakao: require("@/images/section7/kakao.png"),
            lazada: require("@/images/section7/lazada.png"),
            lazada2: require("@/images/section7/lazada2.png"),
            lg: require("@/images/section7/lg.png"),
            line: require("@/images/section7/line.png"),
            loreal: require("@/images/section7/loreal.png"),
            maybelline: require("@/images/section7/maybelline.png"),
            appota: require("@/images/section7/appota.png"),
            momo2: require("@/images/section7/momo2.png"),
            napas: require("@/images/section7/napas.png"),
            nexon: require("@/images/section7/nexon.png"),
            perfectWorld: require("@/images/section7/perfect-world.png"),
            phenikaa: require("@/images/section7/phenikaa.png"),
            phenikaa2: require("@/images/section7/phenikaa2.png"),
            pti: require("@/images/section7/pti.png"),
            sakura: require("@/images/section7/sakura.png"),
            seabank: require("@/images/section7/seabank.png"),
            seabank2: require("@/images/section7/seabank2.png"),
            shopee: require("@/images/section7/shopee.png"),
            shopee2: require("@/images/section7/shopee2.png"),
            techcombank: require("@/images/section7/techcombank.png"),
            tiki: require("@/images/section7/tiki.png"),
            tpbank: require("@/images/section7/tpbank.png"),
            uan: require("@/images/section7/uan.png"),
            uwe: require("@/images/section7/uwe.png"),
            uwe2: require("@/images/section7/uwe2.png"),
            vietcombank: require("@/images/section7/vietcombank.png"),
            vng: require("@/images/section7/vng.png")
        }
    }
}
</script>
<style lang="scss">
.section-7 {
    .w-2 {
        img {
            width: 47%;
        }
    }

    .w-2-5 {
        img {
            width: 43%;
        }
    }

    .w-3 {
        img {
            width: 30%;
        }
    }

    .w-4 {
        img {
            width: 20%;
        }
    }

    p {
        font-size: 20px;
        font-weight: bold;
    }
}
</style>