<template>
    <div class="section-4" :style="{ 'color': 'white' }">
        <div class="container-fluid mt-5">
            <div class="row pt-5">
                <div class="col-sm-12">
                    <Title title="QUYỀN LỢI" paddingTitle="15px 50px 5px 30px" :backgroundTitle="img1" fontSizeTitle="56px"
                        fontWeightTitle="bold" />
                    <div class="d-flex justify-content-center mt-3">
                        <p :style="{ 'font-size': '32px', 'color': '#065F66' }">
                            <b>
                                Tham gia sự kiện của chúng tôi, Quý anh/chị có cơ hội:
                            </b>
                        </p>
                    </div>
                </div>
            </div>
            <div class="row d-flex justify-content-center mt-5">
                <Card :img="img2" title="Cập nhật thị trường"
                    content="Thấu hiểu bức tranh tổng quan về thị trường thông qua tài liệu chuyên ngành và báo cáo Repota 2023 " />
                <Card :img="img3" title="Đón đầu giải pháp"
                    content="Nắm bắt các giải pháp, công cụ, framework, template để ứng dụng luôn vào công việc của doanh nghiệp" />
                <Card :img="img4" title="Trải nghiệm độc đáo"
                    content="Trải nghiệm những hoạt động độc đáo xuyên suốt sự kiện và nhận các phần quà đặc biệt từ chương trình" />
                <Card :img="img5" title="Mở rộng networking"
                    content="Giao lưu, kết nối cùng cộng đồng marketing và kinh doanh tại khu vực miền Nam " />
                <Card :img="img6" title="Gặp gỡ chuyên gia"
                    content="Trao đổi, thảo luận và chia sẻ cùng các diễn giả là chuyên gia hàng đầu trong ngành " />
            </div>
        </div>
        <div
            :style="{ 'background-image': 'url(' + bg + ')', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'position': 'relative', 'padding-top': '200px', 'margin-top': '-100px', 'z-index': -1 }">
            <div class="container" :style="{ 'font-size': '20px', 'padding': '0 150px 25px' }">
                <div class="row">
                    <Title title="AGENDA" :backgroundTitle="img7" fontSizeTitle="56px" />
                </div>
                <div class="nunito">
                    <div class="row mt-5 pb-3 line">
                        <div class="col-lg-3 col-sm-12">
                            13:30 - 14:30
                        </div>
                        <div class="col-lg-9 col-sm-12">
                            Đón tiếp khách mời
                        </div>
                    </div>
                    <div class="row mt-3 pb-3 line">
                        <div class="col-lg-3 col-sm-12">
                            14:30 - 15:00
                        </div>
                        <div class="col-lg-9 col-sm-12">
                            Warm-up và mở đầu chương trình
                        </div>
                    </div>
                    <div class="row mt-3 pb-3 line">
                        <div class="col-lg-3 col-sm-12">
                            15:00 - 15:25
                        </div>
                        <div class="col-lg-9 col-sm-12">
                            Key discussion 1 + Thảo luận: Tổng quan báo cáo Repota 2023 (Doanh nghiệp nên gìn giữ chất riêng
                            như
                            thế
                            nào và ứng dụng các xu hướng Marketing mới)
                        </div>
                    </div>
                    <div class="row mt-3 pb-3 line">
                        <div class="col-lg-3 col-sm-12">
                            15:25 - 15:50
                        </div>
                        <div class="col-lg-9 col-sm-12">
                            Key discussion 2 + Thảo luận: Xây dựng chiến lược truyền thông giúp bứt phá thương hiệu
                        </div>
                    </div>
                    <div class="row mt-3 pb-3 line">
                        <div class="col-lg-3 col-sm-12">
                            15:50 - 16:15
                        </div>
                        <div class="col-lg-9 col-sm-12">
                            Tea Break & Minigame
                        </div>
                    </div>
                    <div class="row mt-3 pb-3 line">
                        <div class="col-lg-3 col-sm-12">
                            16:15 - 16: 30
                        </div>
                        <div class="col-lg-9 col-sm-12">
                            Key discussion 3 + Thảo luận: Làm thế nào để tối ưu tỉ lệ chuyển đổi trong phễu Marketing? (MQL
                            sang
                            SQL)
                        </div>
                    </div>
                    <div class="row mt-3 pb-3 line">
                        <div class="col-lg-3 col-sm-12">
                            16:30 - 17:00
                        </div>
                        <div class="col-lg-9 col-sm-12">
                            Panel Discussion
                        </div>
                    </div>
                    <div class="row mt-3 pb-3">
                        <div class="col-lg-3 col-sm-12">
                            17:00 - 17:30
                        </div>
                        <div class="col-lg-9 col-sm-12">
                            Kết thúc chương trình
                        </div>
                    </div>
                </div>
                <div class="row mt-3 pb-3 d-flex justify-content-center mt-3">
                    <button
                        :style="{ 'width': '308px', 'height': '45px', 'border-radius': '8px', 'color': '#065F66', 'font-size': '16px', 'border': 'none' }">
                        <b>
                            ĐĂNG KÝ NGAY
                        </b>
                    </button>
                </div>
            </div>
            <img :src="img8" alt="" class="d-none d-lg-block img8 opacity0"
                :style="{ 'position': 'absolute', 'bottom': '-300px', 'right': '20px' }">
        </div>
    </div>
</template>

<script>
import { Carousel, Slide } from 'vue3-carousel'
import Card from './Card.vue'
import Title from './Title.vue'

export default {
    name: "Section4",
    components: { Carousel, Slide, Card, Title },
    data() {
        return {
            bg: require("@/images/section4/bg.png"),
            img1: require("@/images/section4/1.png"),
            img2: require("@/images/section4/2.png"),
            img3: require("@/images/section4/3.png"),
            img4: require("@/images/section4/4.png"),
            img5: require("@/images/section4/5.png"),
            img6: require("@/images/section4/6.png"),
            img7: require("@/images/section4/7.png"),
            img8: require("@/images/section4/8.png"),
        }
    },
    created() {
        window.addEventListener('scroll', this.onScroll);
    },
    unmounted() {
        window.removeEventListener('scroll', this.onScroll);
    },
    methods: {
        onScroll() {
            var img8 = this.$el.querySelector('.img8');
            if (img8.getBoundingClientRect().top < window.innerHeight) {
                img8.classList.add('show-up')
            }
        }
    }
}
</script>

<style lang="scss">
.section-4 .line {
    border-bottom: 1px solid white;
}

@media screen and (max-width: 767px) {
    .section-4 {
        span {
            padding: 5px 50px 5px 20px !important;
        }

        .container {
            padding: 0 25px !important;
        }

        .title-image {
            .title {
                font-size: 48px;
            }
        }
    }
}
</style>