<template>
    <div class="section-5"
        :style="{ 'background-image': 'url(' + bg + ')', 'background-position': 'center bottom -100px', 'background-size': 'contain', 'background-repeat': 'no-repeat' }">
        <p :style="{ 'color': '#065F66', 'margin-top': '25px', 'margin-left': '200px', 'font-size': '28px' }">
            <b>
                Repota là báo cáo thường niên thực hiện bởi Adsota Agency nhằm cung cấp <br> những thông tin thiết thực về
                bối
                cảnh
                thị trường, dự đoán xu hướng mới và <br> những góc nhìn mang tính chuyên môn, có chiều sâu của các chuyên
                gia.
            </b>
        </p>
        <div class="container-fluid mt-5">
            <div class="row">
                <div class="col-lg-6 col-sm-12">
                    <div class="read-demo"
                        :style="{ 'background-color': '#C34B27', 'width': '100%', 'height': '654px', 'margin-top': '100px' }">
                        <div
                            :style="{ 'box-shadow': '0 0 10px #555', 'position': 'relative', 'width': '537px', 'height': '662px', 'background-color': '#DEDEDE', 'margin-top': '-100px', 'margin-right': '100px', 'float': 'right' }">

                            <button
                                :style="{ 'width': '351px', 'height': '55px', 'position': 'absolute', 'left': '100px', 'bottom': '-80px', 'border': 'none', 'background-color': '#065F66', 'border-radius': '8px', 'color': 'white', 'font-size': '20px' }">
                                ĐỌC BẢN DEMO
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12 nunito">
                    <p class="fw-bold" :style="{ 'font-size': '20px', 'margin-top': '50px', 'margin-bottom': '50px' }">
                        Repota 2023 là tài liệu chuyên môn giá trị giúp Marketer, những người quản lý, <br> chủ doanh
                        nghiệp...cập
                        nhật những bước chuyển mình của thị trường, đồng thời <br> đánh giá, thay đổi hướng đi để tối ưu
                        chiến
                        lược
                        tiếp thị và phát triển doanh <br> nghiệp một cách toàn diện.
                    </p>
                    <p class="fw-bold" :style="{ 'font-size': '36px', 'color': '#065F66', 'margin-bottom': '5px' }">
                        Điểm nổi bật trong Repota 2023?
                    </p>
                    <ul :style="{ 'font-size': '20px', 'padding-left': '20px' }">
                        <li class="py-2">
                            <b>Phần 1:</b> Những chuyển biến của thị trường trong năm 2022 và nửa đầu 2023
                        </li>
                        <li class="py-2">
                            <b>Phần 2:</b> Dự đoán xu hướng những năm tới
                        </li>
                        <li class="py-2">
                            <b>Phần 3:</b> Marketing Chữa Lành
                        </li>
                        <li class="py-2">
                            <b>Phần 4:</b> Case studies và chia sẻ của chuyên gia
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="container" :style="{ 'margin-top': '200px' }">
            <div class="row">
                <Title title="ĐĂNG KÝ THAM DỰ SỰ KIỆN" subTitle="VÀ NHẬN BÁO CÁO REPOTA 2023" :backgroundTitle="img1"
                    fontSizeTitle="36px" fontSizeSubTitle="36px" colorSubTitle="#065F66" fontWeightSubTitle="bold" />
            </div>
            <div class="row form" :style="{ 'color': '#065F66', 'font-size': '20px' }">
                <div class="col-sm-12 d-flex justify-content-center">
                    <div :style="{ 'width': '100%', 'max-width': '732px' }">
                        <label for="">Họ và tên*</label>
                        <br>
                        <input type="name">
                        <br>
                        <label for="">Số điện thoại*</label>
                        <br>
                        <input type="tel">
                        <br>
                        <label for="">Email*</label>
                        <br>
                        <input type="email">
                        <br>
                        <label for="">Đơn vị công tác*</label>
                        <br>
                        <input type="text">
                        <br>
                        <label for="">Chức vụ*</label>
                        <br>
                        <input type="text">
                        <br>
                        <label for=""
                            :style="{ 'color': 'black', 'font-style': 'italic', 'font-size': '14px', 'font-weight': 'normal' }">Mọi
                            thông
                            tin cung cấp sẽ được xử lý một các bảo mật và an
                            toàn</label>
                        <br>
                        <button @click="Show"
                            :style="{ 'margin-top': '10px', 'font-size': '24px', 'width': '100%', 'color': 'white', 'background-color': '#065F66', 'border-radius': '8px', 'border': 'none', 'height': '58px' }">
                            Đăng Ký Tham Dự
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Title from './Title.vue'
export default {
    name: "Section5",
    components: { Title },
    data() {
        return {
            bg: require("@/images/section5/bg.png"),
            img1: require("@/images/section5/1.png")
        }
    },
    methods: {
        Show() {
            document.querySelector('.popup').classList.remove('d-none')
        }
    }
}
</script>

<style>
.section-5 .form {
    padding-bottom: 200px;
}

.section-5 .form input {
    width: 100%;
    max-width: 732px;
    height: 45px;
    border-radius: 8px;
    border-color: #065F66;
    background-color: #F2F9FB;
    border-width: 1px;
    margin-bottom: 15px;
}

.section-5 .form label {
    font-weight: bold;
}

@media screen and (max-width: 767px) {
    .section-5 p {
        margin: 0 25px !important;
    }

    .section-5 .read-demo {
        width: 100% !important;
        margin: 0 !important;
    }

    .section-5 .read-demo>div {
        width: 100% !important;
        margin: 0 0 100px !important;
    }

    .section-5 .read-demo>div button {
        left: 0 !important;
        width: 100% !important;
    }

    .section-5 .container {
        margin: 0 !important;
    }

    .section-5 .container span {
        background-size: cover !important;
        background-position: center !important;
    }

    .section-5 .form {
        padding-bottom: 100px;
    }
}
</style>