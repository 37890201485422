<template>
    <div class="popup d-none">
        <div class="box">
            <div class="congratulation">
                <div class="title">
                    CẢM ƠN QUÝ ANH/CHỊ ĐÃ ĐĂNG KÝ THÀNH CÔNG
                </div>
                <img :src="img4" alt="">
            </div>
            <div class="content">
                <div class="nunito">
                    <div class="title">
                        Phí Sign-in dự kiến của chương trình:
                    </div>
                    <div class="money">
                        300 000đ
                    </div>
                    <div class="note">
                        Để tham dự chương trình, quý anh/Chị vui lòng chuyển khoản phí Sign-in theo thông tin sau:
                    </div>
                </div>
                <div class="subbox container">
                    <div class="row nunito">
                        <div class="col-lg-8 col-sm-12">
                            <b>Tên tài khoản:</b> NGUYEN THUY TIEN
                            <br>
                            <b>Ngân hàng:</b> Techcombank
                            <br>
                            <b>Số tài khoản:</b> 19038808170017
                            <br>
                            <b>Nội dung chuyển khoản:</b> Tên - 3 chữ số cuối SĐT - EVENT SG
                            <br>
                            <i :style="{ 'font-size': '13px' }">Ví dụ: Nguyễn Văn A - 123 - EVENT SG</i>
                            <br><br>
                            <b :style="{ 'font-size': '12px' }">
                                <i>Lưu ý: Anh chị chú ý viết đúng cú pháp trong nội dung chuyển khoản để chúng tôi có thể
                                    kiểm
                                    tra
                                    một cách nhanh chóng và chính xác nhất.
                                    Quý Anh/Chị có thể xem lại nội dung chuyển khoản phí Sign-in trong email đã đăng ký</i>
                            </b>
                        </div>
                        <div class="col-lg-4 col-sm-12">
                            <div class="text-center">
                                <b>Mã QR thanh toán</b>
                            </div>
                            <div class="qr-box"></div>
                        </div>
                    </div>
                    <div class="row">
                        <button @click="Hide">
                            Về Trang Chủ
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Popup',
    data() {
        return {
            img1: 'url(' + require("@/images/popup/1.png") + ')',
            img2: require("@/images/popup/2.png"),
            img3: require("@/images/popup/3.png"),
            img4: require("@/images/popup/4.png"),
            img5: 'url(' + require("@/images/popup/5.png") + ')',
        }
    },
    methods: {
        Hide() {
            this.$el.classList.add('d-none')
        }
    }
}
</script>
<style lang="scss">
.popup {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, .8);
    display: flex;
    justify-content: center;
    align-items: center;

    .box {
        width: 90%;
        max-width: 1036px;
        background-color: white;

        .congratulation {
            background-image: v-bind(img1);
            position: relative;

            .title {
                width: 465px;
                font-size: 24px;
                text-align: center;
                padding: 170px 0 30px;
                margin: auto;
                color: #065F66;
                font-weight: bold;
            }

            img {
                position: absolute;
                bottom: -23px;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .content {
            text-align: center;
            background-image: v-bind(img5);
            padding: 24px 0 48px;

            .title {
                font-size: 24px;
                color: white;
            }

            .money {
                font-size: 40px;
                font-weight: bold;
                color: white;
            }

            .note {
                font-size: 16px;
                font-style: italic;
                color: white;
            }

            .subbox {
                max-width: 762px;
                text-align: left;
                font-size: 16px;
                background-color: white;
                border-radius: 8px;
                margin-top: 24px;
                padding: 8px 16px;

                .qr-box {
                    background-color: #D1D1D1;
                    width: 122px;
                    height: 122px;
                    margin: 24px auto 0;
                }

                button {
                    background-color: #065F66;
                    border-radius: 8px;
                    color: white;
                    font-size: 16px;
                    width: 149px;
                    height: 45px;
                    margin: 12px auto;
                    border: none;
                }
            }
        }
    }
}
</style>